// const targets = document.querySelectorAll('.js-accordion-target');
// const triggers = document.querySelectorAll('.js-accordion-trigger');

// for(let i = 0; i < triggers.length; i++){
//     triggers[i].addEventListener('click', function(){
//         triggers[i].classList.toggle('open');
//         targets[i].classList.toggle('open');
//     })
// }

$(document).on('click','.js-accordion-trigger',function(){
	$(this).toggleClass('open')
	$(this).next('.js-accordion-target').toggleClass('open')
});
