

var scroll = new SmoothScroll('a[href*="#"]', {
    speed:1000,
    speedAsDuration:true,
    header: "#inc_header"
});

//アンカーリンクの設定。スクロール時、header分がかぶらないように調整
$('a[href^="#"]').click(function(){
	let speed = 500;
	let href= $(this).attr("href");
	let target = $(href == "#" || href == "" ? 'html' : href);
	let position = target.offset().top - $('#inc_header').height();
	$("html, body").animate({scrollTop:position}, speed, "swing");
	return false;
});

$(function(){
    if ( $('.error')[0] ) {
        $('.mw_wp_form').addClass('mw_wp_form_error');
            var errorEl = $('.mw_wp_form').eq(0);
            var position = errorEl.parent().offset().top - 100; //740の部分は上からなんpxの位置にスクロールしたいかを指定する。
        $('body,html').delay(200).animate({scrollTop:position}, 400, 'swing');
    }
});


