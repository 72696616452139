'use strict';

import "@babel/polyfill";
import { ua } from './view/_ua';
import { header } from './view/_header';
import { height } from './view/_height';
import { news } from './view/_news';
import { scroll } from './view/_scroll';
import { footer } from './view/_footer';
import { select } from './view/_select';
import { accordion } from './view/_accordion';
import { smoothScroll } from './view/_smoothScroll';
import { modal } from './view/_modal';