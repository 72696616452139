const selectBoxes = $('.js-select');
const selectBoxItems = $('.js-option');
selectBoxes.click(function() {
	const selectBoxList = $(this).find('ul');
	if(selectBoxList.hasClass('is-open')) {
		selectBoxList.removeClass('is-open');
	}
	else {
		selectBoxList.addClass('is-open');
	}
});
selectBoxItems.click(function() {
	const selectBox = $(this).closest('.js-select');
	selectBox.find('input').val($(this).data('value'));
	selectBox.find('.js-value').text($(this).text());
});
