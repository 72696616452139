const _menuBtn = $('#js-hamburger')
const _header = $('#header')
const _navigation = $('#js-navigation')

// _menuBtn.click(function(){
// 	$('html').toggleClass('is-fixed')
// 	_menuBtn.toggleClass('is-open')
// 	_navigation.toggleClass('is-show')
// })

// ハンバーガーボタンの挙動
$(document).on('click','#js-hamburger',function(){
	$('html').toggleClass('is-fixed')
	$('#js-hamburger').toggleClass('is-open')
	$('#js-navigation').toggleClass('is-show')
});


$(window).on('load', function(){
// l-mainに各クリニックのクラス名がついていたら、ロゴのパスを変える
	$(function(){
		if($('.l-main').hasClass('akasaka')){
			$('.l-header__logo').children('img').attr('src', '/assets/img/clinic/akasaka/logo_header.svg');
			$('.l-header__logo').addClass('l-header__logo-clinic');
		}
	});
	$(function(){
		if($('.l-main').hasClass('ebisu-gardenplace')){
			$('.l-header__logo').children('img').attr('src', '/assets/img/clinic/ebisu-garden/logo_header.svg');
			$('.l-header__logo').addClass('l-header__logo-clinic');
		}
	});
	$(function(){
		if($('.l-main').hasClass('ebisu')){
			$('.l-header__logo').children('img').attr('src', '/assets/img/clinic/ebisu/logo_header.svg');
			$('.l-header__logo').addClass('l-header__logo-clinic');
		}
	});
	$(function(){
		if($('.l-main').hasClass('izumi-garden')){
			$('.l-header__logo').children('img').attr('src', '/assets/img/clinic/izumi/logo_header.svg');
			$('.l-header__logo').addClass('l-header__logo-clinic');
		}
	});
	$(function(){
		if($('.l-main').hasClass('shinjuku')){
			$('.l-header__logo').children('img').attr('src', '/assets/img/clinic/shinjuku/logo_header.svg');
			$('.l-header__logo').addClass('l-header__logo-clinic');
		}
	});
	$(function(){
		if($('.l-header').hasClass('shiroyama-garden')){
			console.log('yes')
			$('.l-header__logo').children('img').attr('src', '/assets/img/clinic/shiroyama/logo_header.svg');
			$('.l-header__container').addClass('l-header__logo-clinic');
		}
		if($('.l-main').hasClass('shiroyama-garden')){
			console.log('yes')
			$('.p-clinicInfo').addClass('l-header__logo-clinic');
		}
	});
	$(function(){
		if ($('.l-main').hasClass('ueno-okachimachi')){
			$('.l-header__logo').children('img').attr('src', '/assets/img/clinic/ueno-okachimachi/logo_header.svg');
			$('.l-header__logo').addClass('l-header__logo-clinic');
		}
	});
})



// animation
var _timer = '';
$(window).on('load resize', function(){
	if (_timer) {
		clearTimeout(_timer);
	}
	_timer = setTimeout(function(){
		var _wWidth = $(window).width();
		if (_wWidth < 768) {
			let _height = $(window).outerHeight(true);
			let _footer = $('.l-footer__clinic').offset().top;
			let scroll = $(window).scrollTop() + $(window).height();
			$(window).scroll(function(){
				if($(this).scrollTop() + _height > _footer){
					$('#js-follow').fadeOut();
				} else {
					$('#js-follow').fadeIn();
				}
			})
		} else {
			let _pos = 0;
			function ScrollAnime() {
				var _elemTop = $('.l-main').offset().top;
				var _scroll = $(window).scrollTop();
				var _height = _header.outerHeight(true);
				if(_scroll == _pos) {
				}else if(_elemTop > _scroll || 0 > _scroll - _pos){
					_header.removeClass('is-up');
					_header.addClass('is-down');
				}else{
					_header.removeClass('is-down');
					_header.addClass('is-up');
				}
				_pos = _scroll;
			}
			$(window).scroll(function(){
				ScrollAnime();
			});
		}
	}, 200);
});
