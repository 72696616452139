
function ModalFunction(){
    const openModal = document.querySelectorAll('.js-open-modal');
    const modalArea = document.querySelectorAll('.js-modalArea');
    const closeModal = document.querySelectorAll('.js-close-modal');
    const modalBg = document.querySelectorAll('.js-modalBg');
    
    for(let i = 0; i < openModal.length; i++){
        openModal[i].addEventListener('click', function(){
            if(!modalArea[i].classList.contains('is-show')){
                modalArea[i].classList.add('is-show');
                bodyScrollPrevent(true); //スクロール制御関数
            }else{
                modalArea[i].classList.remove('is-show');
                bodyScrollPrevent(false); //スクロール制御関数
            }
        })
        closeModal[i].addEventListener('click', function(){
            if(!modalArea[i].classList.contains('is-show')){
                modalArea[i].classList.add('is-show');
                bodyScrollPrevent(true); //スクロール制御関数
            }else{
                modalArea[i].classList.remove('is-show');
                bodyScrollPrevent(false); //スクロール制御関数
            }
        })
        modalBg[i].addEventListener('click', function(){
            if(!modalArea[i].classList.contains('is-show')){
                modalArea[i].classList.add('is-show');
                bodyScrollPrevent(true); //スクロール制御関数
            }else{
                modalArea[i].classList.remove('is-show');
                bodyScrollPrevent(false); //スクロール制御関数
            }
        })
    }

    //スクロール制御のための関数↓
    function bodyScrollPrevent(flag){
        let scrollPosition;
        const body = document.getElementsByTagName('body')[0];
        const ua = window.navigator.userAgent.toLowerCase();
        const isiOS = ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1 || ua.indexOf('macintosh') > -1 && 'ontouchend' in document;
        const scrollBarWidth = window.innerWidth - document.body.clientWidth;
        if(flag == true){
          body.style.paddingRight = scrollBarWidth + 'px';
          if(isiOS){
            scrollPosition = -window.pageYOffset;
            body.style.position = 'fixed';
            body.style.width = '100%';
            body.style.top = scrollPosition + 'px';
          } else {
            body.style.overflow = 'hidden';
          }
        } else if(flag == false){
            body.style.paddingRight = '';
            if(isiOS){
              scrollPosition = parseInt(body.style.top.replace(/[^0-9]/g,''));
              body.style.position = '';
              body.style.width = '';
              body.style.top = '';
              window.scrollTo(0, scrollPosition);
            }else {
              body.style.overflow = '';
            }
        }
        
    };
  
}



ModalFunction();

