const targets = document.querySelectorAll('.p-clinicNews__list');
const triggers = document.querySelectorAll('.p-clinicNews__button');

window.onload = function(){

for(let i = 0; i < triggers.length; i++){
    triggers[i].addEventListener('click', function(){
    for(let i = 0; i < triggers.length; i++){
        triggers[i].classList.remove('is-current');
    }
    triggers[i].classList.add('is-current');
    
    for(let i = 0; i < targets.length; i++){
        targets[i].classList.remove('is-active');
    }
    targets[i].classList.add('is-active');
    });
};

}